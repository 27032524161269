.MuiToggleButton-root.MuiToggleButton-root {
  color: #999999;
  transition: color 0.2s, background-color 0.2s;
}

.MuiToggleButton-root.MuiToggleButton-root:hover {
  background-color: #0c46f2;
}

.MuiToggleButton-root.MuiToggleButton-root.black:hover {
  background-color: #444444;
}

.MuiToggleButton-root.MuiToggleButton-root.red:hover {
  background-color: #ff5c5c;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected:hover {
  background-color: #0c46f2;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected.black:hover {
  background-color: #444444;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected.red:hover {
  background-color: #ff5c5c;
}

.MuiToggleButton-root.MuiToggleButton-root:active {
  color: white;
  background-color: #0c46f2;
}

.MuiToggleButton-root.MuiToggleButton-root.black:active {
  background-color: #444444;
}

.MuiToggleButton-root.MuiToggleButton-root.red:active {
  background-color: #ff5c5c;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected {
  color: white;
  background-color: #0c46f2;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected.black {
  background-color: #444444;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected.red {
  background-color: #ff5c5c;
}

.MuiToggleButton-label.MuiToggleButton-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  height: 100%;
  text-align: left;
}
